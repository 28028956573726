import * as React from "react";
import { useEffect, useState } from "react";
import TwoColumnLayout from "../components/layout/TwoColumnLayout";
import Sidebar from "../components/layout/Sidebar/Sidebar";
import {
  PortalEvent,
  useStaticEvents,
} from "../components/context/EventProvider";
import { helpers } from "../components/common/Calendar/Calendar";
import { CalPreview, CalDate } from "../components/common/Calendar";
import { Row, Col } from "react-bootstrap";
import { FirebaseContext } from "../components/Firebase";
import AuthCheck from "../components/Firebase/AuthCheck";
import PortalHero from "../components/common/ntmwd/layout/PortalHero";
import { WmkSeo } from "wmk-seo";
import IEFallback from "../components/layout/Fallback/IEFallback";
import { dateFormat } from "../vars/Time";
import { graphql } from "gatsby";
import { PortalHeroQuery } from ".";
import { Img } from "wmk-image";

const EventsPage = ({ data }: PortalHeroQuery) => {
  const [now, setNow] = useState(new Date());
  const [upcoming, setUpcoming] = useState<PortalEvent[]>(
    helpers.matching(useStaticEvents(), now, "month")
  );
  const copy = [...upcoming];
  const sorted = {};
  copy.forEach((e) => {
    const key = e.start.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const group = sorted[key];
    if (!group) {
      sorted[key] = [];
      sorted[key].push(e);
    } else {
      if (
        helpers.isSameDay(new Date(sorted[key][0].start), new Date(e.start))
      ) {
        sorted[key].push(e);
      }
    }
  });
  const sortedArray = Object.entries(sorted);
  const hero = new Img(data.hero.mainImage);
  hero.addCrop("ogImage", data.hero.mainImage.twitterImage);
  hero.addCrop("twitterImage", data.hero.mainImage.twitterImage);
  useEffect(() => {
    setNow(new Date());
  }, [setUpcoming]);
  return (
    <IEFallback>
      <WmkSeo.Meta
        title={`${now.toLocaleString("en-US", {
          month: "long",
        })} Upcoming Events`}
        siteTitle="NTMWD Board Portal"
        twitterImageUrl={hero.url("twitterImage")}
        twitterHandle={''}
        ogImageUrl={hero.url("ogImage")}
        slug={"events"}
        baseUrl="https://board.ntmwd.com"
      />
      <PortalHero hero={hero}>{`${now.toLocaleString("en-US", {
        month: "long",
      })} Upcoming Events`}</PortalHero>
      <FirebaseContext.Consumer>
        {({ user }) =>
          user ? (
            <TwoColumnLayout
              Main={() => {
                return (
                  <Row
                    className="flex-column cal-module"
                    style={{ width: "100%" }}
                  >
                    {sortedArray.map((day) => {
                      //const mom = moment(day[0]);

                      const up = day[1];
                      const upTitle = new Date(day[0]);
                      return (
                        <Col key={dateFormat.format(upTitle)}>
                          <CalDate smaller>
                            {dateFormat.format(upTitle)}
                          </CalDate>
                          {up.map((event) => {
                            const { title, start, end, description, dst } =
                              event;
                            return (
                              <Col key={title + dateFormat.format(start)}>
                                <CalPreview
                                  title={title}
                                  start={start}
                                  end={end}
                                  description={description}
                                  dst={dst}
                                />
                              </Col>
                            );
                          })}
                        </Col>
                      );
                    })}
                  </Row>
                );
              }}
              Sidebar={Sidebar}
            />
          ) : (
            <AuthCheck />
          )
        }
      </FirebaseContext.Consumer>
    </IEFallback>
  );
};
export default EventsPage;

export const query = graphql`
  {
    hero: contentfulSettingsGlobal(title: { eq: "Portal Hero" }) {
      globalId: title
      mainImage {
        gatsbyImageData
        ogImage: gatsbyImageData(width: 1200, height: 630, quality: 80)
        twitterImage: gatsbyImageData(width: 876, height: 438, quality: 80)
        title
        file {
          url
          contentType
        }
      }
    }
  }
`;
